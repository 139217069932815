import React, { useEffect, useState } from 'react'
import "./post.css"
import { getPosts } from '../../../../services/userApiCalls';
import { message, Spin } from 'antd';


const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getPostList()
    }, [])

    const getPostList =async () => {
        setIsLoading(true)
      
        try {
          const resp =await getPosts()
          if (resp.status === 200) {
            setPosts(resp?.data?.data)
            
          
          }else{
            message.error({
              content: resp?.response?.data?.message,
              style: {
                position: 'absolute',
                right: '0px',
                top: '0px',
              }
            })
          }
          setIsLoading(false)
         
          
        } catch (error) {
            setIsLoading(false)
        }
       
      };

      function formatDate(isoString) {
        const date = new Date(isoString);
      
        // Get date components
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
      
        // Get time components
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const amPm = hours >= 12 ? 'PM' : 'AM';
      
        // Convert to 12-hour format
        hours = hours % 12 || 12;
      
        return `${month} ${day}, ${year} at ${hours}:${minutes}:${seconds}${amPm}`;
      }
    
  return (
    <>
       {posts.map((post)=>{
        return <div class="post-items">
    <div class="post-items-content">
        <div class="post-contentTop">
            <div class="post-contentTopLeft">
                <span class="post-fb-icon">
                    <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.396 19H6.396V10.99H10L10.396 7.01H6.396V5C6.396 4.73478 6.50136 4.48043 6.68889 4.29289C6.87643 4.10536 7.13078 4 7.396 4H10.396V0H7.396C6.06992 0 4.79815 0.526784 3.86047 1.46447C2.92278 2.40215 2.396 3.67392 2.396 5V7.01H0.396L0 10.99H2.396V19Z" fill="white"/>
                    </svg>
                </span>
                <div class="post-ownerWrap">
                    <span class="post-ownerWrap">{post?.name || ''}</span>
                    <span onClick={()=>window.open(post?.profile_link, "_blank")} style={{cursor:"pointer"}}>View Profile</span>
                </div>
            </div>
            <div class="post-contentTopRight">
                <button class="post-btn" style={{cursor:"pointer"}} onClick={()=>window.open(post?.post_link, "_blank")} >Open post</button>
            </div>
        </div>
        <div class="post-contentBtm">
            <div class="post-starIcon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8538 1.50033C10.7688 1.34858 10.6448 1.22223 10.4947 1.13429C10.3446 1.04635 10.1738 1 9.99981 1C9.82585 1 9.65502 1.04635 9.50492 1.13429C9.35482 1.22223 9.23087 1.34858 9.14581 1.50033C8.3089 3.01797 7.62005 4.61266 7.08881 6.26233C6.94981 6.69333 6.53781 6.99233 6.06581 7.00533C4.63503 7.04294 3.2089 7.18499 1.79881 7.43033C1.02481 7.56633 0.73381 8.44833 1.28381 8.98633C2.35054 10.0299 3.49007 10.9963 4.69381 11.8783C5.06081 12.1473 5.21181 12.6083 5.07181 13.0303C4.5385 14.6334 4.15683 16.2829 3.93181 17.9573C3.83181 18.7123 4.63981 19.2453 5.34181 18.8853C6.73385 18.1716 8.06498 17.3448 9.32181 16.4133C9.51867 16.2696 9.75609 16.1922 9.99981 16.1922C10.2435 16.1922 10.481 16.2696 10.6778 16.4133C11.9344 17.3452 13.2655 18.172 14.6578 18.8853C15.3588 19.2453 16.1678 18.7123 16.0678 17.9573C15.8429 16.2826 15.4612 14.6327 14.9278 13.0293C14.7878 12.6093 14.9378 12.1473 15.3058 11.8783C16.5095 10.9963 17.6491 10.0299 18.7158 8.98633C19.2658 8.44833 18.9758 7.56633 18.2008 7.43033C16.7909 7.18356 15.3647 7.0415 13.9338 7.00533C13.7076 7.00173 13.488 6.92845 13.3049 6.79549C13.1218 6.66253 12.9842 6.47635 12.9108 6.26233C12.3795 4.613 11.6907 3.01765 10.8538 1.50033Z" stroke="#b5b5b5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            </div>
            <div class="post-info">
                <span class="post-info-title">{post?.data || ''}</span>
                <div class="post-readmore-wraper">
                    <span class="post-readMore-btn">Read More</span>
                    <span class="post-time">
                        <strong>Imported on:</strong>
                       {formatDate(post?.createdAt)}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="post-items-like post-blueBg">
        <span class="postCount">{post?.like_count || 0}</span>
        <span>Likes</span>
    </div>
    <div class="post-items-comment post-blueBg">
        <span class="postCount">{post?.comment_count || 0}</span>
        <span>Comments & Replies</span>
    </div>
    <div class="post-add-msg">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM15 11H11V15H9V11H5V9H9V5H11V9H15V11Z" fill="#515def"/>
        </svg>
        <span>Add Message...</span>
    </div>
</div>
       }) }
    </>
   
  )
}

export default Posts